// Generated by Framer (401b244)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cip2N8HSD"];

const serializationHash = "framer-yoJVr"

const variantClassNames = {cip2N8HSD: "framer-v-1ypf8ky"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cip2N8HSD", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ypf8ky", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cip2N8HSD"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1o9jja5"} data-framer-name={"Group"} fill={"rgba(0,0,0,1)"} intrinsicHeight={35} intrinsicWidth={30} layoutDependency={layoutDependency} layoutId={"I585:4256;208:110831"} svg={"<svg width=\"30\" height=\"35\" viewBox=\"0 0 30 35\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M16.5771 32.625H29.9996V33.1767H16.5771V32.625Z\" fill=\"white\"/>\n<path d=\"M26.9898 32.8782L14.0823 0L13.7171 3.95847e-10L9.18555 11.5888L9.75809 11.5889L11.0382 8.39507L20.4795 32.8782H26.9898Z\" fill=\"white\"/>\n<path d=\"M2.39635 32.2662C1.51363 33.6303 0 33.6303 0 33.6303V34.0766C0 34.0766 1.76224 34.3653 3.45428 34.1639C5.04561 33.9365 6.1381 33.3812 6.1381 33.3812C6.1381 33.3812 8.12193 32.2732 8.98942 30.755C9.85691 29.2367 10.4992 27.0158 10.4992 27.0158C10.4992 27.0158 11.2398 24.1286 12.4051 22.0483C13.301 20.5848 14.7876 20.739 14.7876 20.739V20.2909C14.7876 20.2909 12.8816 19.8352 10.7361 20.1871C9.23023 20.2909 7.52151 21.6908 7.52151 21.6908C7.52151 21.6908 5.90693 23.2367 5.35802 24.436C4.76062 25.5157 4.05696 27.9522 4.05696 27.9522C4.05696 27.9522 3.13187 31.2367 2.39635 32.2662Z\" fill=\"white\"/>\n</svg>\n"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yoJVr.framer-1tkddfe, .framer-yoJVr .framer-1tkddfe { display: block; }", ".framer-yoJVr.framer-1ypf8ky { height: 35px; overflow: hidden; position: relative; width: 30px; }", ".framer-yoJVr .framer-1o9jja5 { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZgWv_0zBC: React.ComponentType<Props> = withCSS(Component, css, "framer-yoJVr") as typeof Component;
export default FramerZgWv_0zBC;

FramerZgWv_0zBC.displayName = "Logo";

FramerZgWv_0zBC.defaultProps = {height: 35, width: 30};

addFonts(FramerZgWv_0zBC, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})